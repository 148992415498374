import React from "react"
import ReactDOM from "react-dom"

// Use a ternary operator to make sure that the document object is defined
let portalRoot;

const getPortalRoot = () => {
  if(!portalRoot){
    portalRoot = typeof document !== `undefined` ? document.getElementById('___modals') : null
    console.log('getPortalRoot',document,portalRoot);
  }
  return portalRoot;
}

class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.el = typeof document !== `undefined` ? document.createElement('div') : null
  }

  componentDidMount() {
    // The portal element is inserted in the DOM tree after
    // the Modal's children are mounted, meaning that children
    // will be mounted on a detached DOM node. If a child
    // component requires to be attached to the DOM tree
    // immediately when mounted, for example to measure a
    // DOM node, or uses 'autoFocus' in a descendant, add
    // state to Modal and only render the children when Modal
    // is inserted in the DOM tree.
    portalRoot = getPortalRoot();
    if(portalRoot){
      portalRoot.appendChild(this.el);
    }
  }

  componentWillUnmount() {
    portalRoot = getPortalRoot();
    if(portalRoot){
      portalRoot.removeChild(this.el);
    }
  }

  render() {
    const { children } = this.props
    // Check that this.el is not null before using ReactDOM.createPortal
    if (this.el) {
      return ReactDOM.createPortal(children, this.el)
    } else {
      return null
    }

  }
}

export default Modal