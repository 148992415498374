import React from "react"
import { graphql, Link, StaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

class ProjectSingle extends React.Component{

  constructor(props) {
    super(props);

    this.setStartingElRef = element => {
      this.startingPageEl = element;
    };

  }

  startingSmoothScroll(){
    const {startingPageEl} = this;
    setTimeout(()=>{
      if(!!startingPageEl && typeof startingPageEl.scrollIntoView === 'function')
      startingPageEl.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "end"
      })
    },1500)
  }

  componentDidMount() {
    this.startingSmoothScroll();
  }

  componentDidUpdate() {
    this.startingSmoothScroll();
  }

  clickNextPrev(e){
    var firstPageEl = document.querySelector('.p__single__p--0'),
      scrollTop = window.scrollY,
      dir = e.target.dataset.dir,
      offsetTop = firstPageEl.offsetTop - 25,
      elSize = firstPageEl.offsetHeight + 25 ,
      offsetIndex = dir === 'up' ? -1 : 1,
      targetIndex = Math.round(scrollTop/elSize) + offsetIndex;

    window.scrollTo({
      top:targetIndex * (elSize) + offsetTop
    })
  }


  render (props){
    const { group, page = 1 } = this.props;
    const self = this;
    return (
  <div className={`p__single__wrapper theme-${group==='michel'?'bronze':'silver'}`}>
    <Link to={`/${group}-protsenko`} className={`p__single__overlay`} />
    <nav>
      <Link to={`/${group}-protsenko`} className="p__single__nav p__single__nav--close icon-icon-close" />
      <a download href={`/pdf/Pdf.${group==='alexia'?'Architecture':'Urbanisme'}BAT_compressed.pdf`} className="p__single__nav p__single__nav--download icon-icon-download" aria-label={"télécharger le PDF"} > </a>
      <button type="button" data-dir="up" onClick={self.clickNextPrev.bind(self)} className="p__single__nav p__single__nav--up icon-icon-up" aria-label={"monter"} />
      <button type="button" data-dir="down" onClick={self.clickNextPrev.bind(self)} className="p__single__nav p__single__nav--down icon-icon-down" aria-label={"descendre"} />
    </nav>
    <div className={"p__single"}>
      <StaticQuery
        query={graphql`
        
query PdfImages {
  allFile(
    filter: {name: {regex: "/Pdf.(Urbanisme|Architecture)/"}, ext: {eq: ".png"}}
    sort: {order: ASC, fields: name}
  ) {
    edges {
      node {
        name
        absolutePath
        publicURL
        childImageSharp {
          gatsbyImageData(
            width:1700,
            layout: CONSTRAINED,
            placeholder: DOMINANT_COLOR
            outputPixelDensities: [1,2,3] 
          )
        }
      }
    }
  }
}
      
        `}
        render={data => (
          <>
            {data.allFile.edges.map(n=>n.node).filter(file=>(group==='alexia'?/Architecture/:/Urbanisme/).test(file.name)).map((file,key)=>{
              const image = getImage(file)
              return (
              <div className={`p__single__p p__single__p--${key}`} key={key}>
                {key===(page-1)?<div className={`p__single__initscroll`} ref={self.setStartingElRef} />:''}
                <GatsbyImage alt={`page ${key+1}`} image={image} />
              </div>
              )})}
          </>
        )}
        />
    </div>
  </div>
)
  }
}


export default ProjectSingle
