import React from "react"
import { graphql } from "gatsby"
import Modal from "../components/modal";

import ProjectSingle from "../components/project-single"
import SEO from "../components/seo"

const ProjectPageWrapper = (props) => {
    const  project = props.data.allProjectsJson.edges[0].node
    const { group, page } = project,
      projectListPath = `/${group}-protsenko`;

    return (
      <Modal parentPath={projectListPath}>
        <SEO title={project.title} />
        <ProjectSingle group={group} project={project} page={page} />
      </Modal>
    )
}

export default ProjectPageWrapper

export const query = graphql`
  query($slug: String!) {
    allProjectsJson(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          title
          page
          group
          slug
        }
      }
    }
  }
`
